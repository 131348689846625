@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply relative min-h-screen bg-grey-lighter text-grey-dark;
  }

  body {
    @apply overscroll-none;
  }

  :root {
    --blue-dark: 0 96 215; /* #0060d7 */
    --blue-darker: 0 61 171; /* #003dab */
    --blue-default: 0 137 235; /* #0089eb */
    --blue-light: 77 172 241; /* #4dacf1 */
    --blue-lighter: 179 220 249; /* #b3dcf9 */
    --error: 197 42 26; /* #c52a1a */
    --green-dark: 44 134 70; /* #2c8646 */
    --green-darker: 23 92 44; /* #175c2c */
    --green-default: 78 181 35; /* #4eb523 */
    --green-light: 134 219 79; /* #86db4f */
    --green-lighter: 196 252 159; /* #c4fc9f */
    --grey-dark: 45 45 45; /* #2d2d2d - Base text color */
    --grey-darker: 20 20 20; /* #141414 */
    --grey-default: 125 125 125; /* #7d7d7d */
    --grey-light: 222 222 222; /* #dedede */
    --grey-lighter: 242 242 242; /* #f2f2f2 */
    --grey-lightest: 247 247 247; /* #f7f7f7 */
    --orange-dark: 147 52 1; /* #933401 */
    --orange-darker: 87 31 1; /* #571f01 */
    --orange-default: 208 74 2; /* #d04a02 */
    --orange-light: 253 100 18; /* #fd6412 */
    --orange-lighter: 254 183 145; /* #feb791 */
    --purple-dark: 107 44 218; /* #6b2cda */
    --purple-darker: 75 32 171; /* #4b20ab */
    --purple-default: 142 52 244; /* #8e34f4 */
    --purple-light: 176 86 246; /* #b056f6 */
    --purple-lighter: 220 180 252; /* #dcb4fc */
    --red-dark: 170 36 23; /* #aa2417 */
    --red-darker: 116 25 16; /* #741910 */
    --red-default: 224 48 30; /* #e0301e */
    --red-light: 232 97 83; /* #e86153 */
    --red-lighter: 247 200 196; /* #f7c8c4 */
    --rose-dark: 164 62 80; /* #a43e50 */
    --rose-darker: 110 42 53; /* #6e2a35 */
    --rose-default: 217 57 84; /* #d93954 */
    --rose-light: 226 117 136; /* #e27588 */
    --rose-lighter: 241 186 195; /* #f1bac3 */
    --success: 34 153 46; /* #22992e */
    --tangerine-dark: 174 104 0; /* #ae6800 */
    --tangerine-darker: 113 67 0; /* #714300 */
    --tangerine-default: 235 140 0; /* #eb8c00 */
    --tangerine-light: 255 169 41; /* #ffa929 */
    --tangerine-lighter: 255 220 169; /* #ffdca9 */
    --warning: 255 191 31; /* #ffbf1f */
    --yellow-dark: 194 138 0; /* #c28a00 */
    --yellow-darker: 133 95 0; /* #855f00 */
    --yellow-default: 255 182 0; /* #ffb600 */
    --yellow-light: 255 200 61; /* #ffc83d */
    --yellow-lighter: 255 236 189; /* #ffecbd */
    --white: 255 255 255; /* #ffffff */
    --black: 0 0 0; /* #000000 */

    --text-disabled: 125 125 125; /* #7d7d7d */
  }
}

@layer components {
  .input {
    @apply w-full rounded-sm border border-solid border-gray-300 text-sm font-normal text-grey-dark;
    &:hover {
      @apply w-full rounded-sm border border-solid border-gray-300 text-sm font-normal text-grey-dark;
    }
  }

  .input-xs {
    @apply h-6 px-2 text-xs;
    &:hover {
      @apply text-xs;
    }
  }
  .input-sm {
    @apply h-8 px-3;
  }
  .input-md {
    @apply h-10 px-4;
  }
  .input-xl {
    @apply h-12 px-5;
  }

  .absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

:root {
  --font-size-primary: 0.7rem;
  --font-size-secondary: 0.7rem;
  --font-size-tertiary: 0.65rem;

  --font-size-title-primary: 1.725rem;
  --font-size-title-secondary: 1.2rem;
  /*
   react-toastify adaptions to make it compliant as AppKit's alert
   https://analyticapps.global.hosting.pwc.com/appkit/docs/react/v3.6.0/?path=/story/alert--alert
  */
  --toastify-toast-width: auto;

  --toastify-text-color-light: #2d2d2d;

  --toastify-icon-color-info: #de536f;
  --toastify-icon-color-success: #22992e;
  --toastify-icon-color-warning: #ffbf1f;
  --toastify-icon-color-error: #c52a1a;

  --toastify-color-progress-info: #de536f;
  --toastify-color-progress-success: #22992e;
  --toastify-color-progress-warning: #ffbf1f;
  --toastify-color-progress-error: #c52a1a;
}
